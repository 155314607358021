/*
 * 업무구분: 가입설계
 * 화 면 명: MSPPI600M
 * 화면설명: My플랜 관리
 * 접근권한: 
 * 작 성 일: 2023.01.25
 * 작 성 자: ---
 */
 <template>
  <ur-page-container class="msp" title="My플랜관리" :show-title="true" type="subpage" :action-type="actionSlot" :topButton="true" @on-header-left-click="fn_HistoryBack" @on-scroll-bottom="fn_Search('N')">
    <!-- Content영역 -->
    <template #frame-header-fixed>
      <!-- 상세조회 조회 키워드 있는 경우 노출 -->
      <ur-box-container alignV="start"  direction="column" class="search-result-box bd-b-Ty1" v-show="isRstKeyword"> 
        <strong class="tit">검색결과</strong>
        <mo-tab-box default-idx="1" primary class="ns-tabs-box-o nw-tab-sub ns-tabs-box2 no-tab">
          <mo-tab-label idx="1" @click="fn_OpenPop">
            <span class="tit">분류</span>
            <span class="txt">{{ getCodeName }}</span> 
          </mo-tab-label>
          <mo-tab-label idx="2" v-if="!$bizUtil.isEmpty(zaPrdtNmParam) && !$bizUtil.isEmpty(productCd)" @click="fn_OpenPop">
            <span class="tit">상품</span>
            <span class="txt" style="max-width:220px">{{ zaPrdtNmParam }}</span> 
          </mo-tab-label>
        </mo-tab-box>
      </ur-box-container>
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-sum">
        <mo-list-item>
          <mo-checkbox v-model="isSelectAll" size="small" @input="selectAllItemsFunc(isSelectAll)" />
          <div v-if="checkCount < 1" class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="sum">총 <strong class="crTy-blue3"> {{ items.length }}</strong> 건</span>
            </div>
          </div>
          <div v-else class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="sum">선택 <strong class="crTy-blue3"> {{ checkCount }}</strong> 건</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
    </template>
    <ur-box-container direction="column" alignV="start" class="ns-list-area"> 
      <!-- List Contents START -->
      <ur-box-container v-if="items.length > 0" alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list">
        <mo-list :list-data="items">
          <template #list-item="{item, index}">
            <mo-list-item :class="{'on' : item.checked === true}">
              <mo-checkbox v-model="item.checked" @input="fn_checkedList(item.disabled, index)"/>
              <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <span class="name txtSkip2">{{item.zaPrdtNm}}</span>
                  <mo-badge v-if="item.zaMyPnLvlCd === '0'" class="badge-sample-badge lightblue2 sm" text="개인">개인</mo-badge>
                  <mo-badge v-else-if="item.zaMyPnLvlCd === 'A'" class="badge-sample-badge lightblue sm" text="표준설계" shape="status">표준설계</mo-badge> <!-- 표준설계 Bage 표시 // -->
                  <mo-badge v-else-if="item.zaMyPnLvlCd === '1'" class="badge-sample-badge lightgreen sm" text="지점" shape="status">지점</mo-badge> <!-- 지점 Bage 표시 // -->
                  <mo-badge v-else-if="item.zaMyPnLvlCd === '2'" class="badge-sample-badge lightorange sm" text="지역단" shape="status">지역단</mo-badge> <!-- 지역단 Bage 표시 -->
                  <mo-badge v-else-if="item.zaMyPnLvlCd === '3'" class="badge-sample-badge lightred sm" text="사업부" shape="status">사업부</mo-badge> <!-- 사업부 Bage 표시 // -->
                </div>
                <div class="list-item__contents__info">
                  <span class="crTy-bk1">{{item.zaPlanNm}}</span>
                </div>
                <div class="list-item__contents__info">
                  <span :class="item.class">{{`${item.zaUserNm} (${item.zaClctCnsltNo})`}}</span>
                </div>
              </div>
            </mo-list-item>
          </template>
        </mo-list>
      </ur-box-container>
      <!-- NoData 영역: start  -->
      <ur-box-container v-else alignV="start" componentid="ur_box_container_002" direction="column" class="no-data">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__info">
              <span>데이터가 없습니다.</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
      <!-- NoData 영역: end  -->
      <!-- List Contents END -->
      <!-- <ur-box-container alignV="start" componentid="" direction="column" class="ns-sch-area">
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative bottom60">
          <div class="relative-div">
            <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="open()">설계삭제</mo-button>
          </div>
        </ur-box-container>  
      </ur-box-container> -->
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-sch-area">
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative bottom60" v-show="lv_isBtnDlt">
        <!-- <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow bottom60" v-show="lv_isBtnDlt"> -->
          <div class="relative-div">
            <mo-button componentid="" color="primary" shape="primary" size="medium" @click="fn_Delete" class="ns-btn-round white">설계삭제</mo-button>
          </div>
        </ur-box-container>
      </ur-box-container>
      <!-- 설계 삭제 버튼 BottomSheet START -->
      <!-- <mo-bottom-sheet ref="bottomSheet1" :close-btn="false" class="ns-bottom-sheet no-background" noHeader>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
            <div class="relative-div">
              <mo-button componentid="" color="primary" shape="primary" size="medium" @click="fn_Delete" class="ns-btn-round white">설계삭제</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet> -->
      <!-- 설계 삭제 버튼 BottomSheet END -->
      <!-- 설계 삭제 Confirm BottomSheet START -->
      <mo-bottom-sheet ref="bottomSheet2" :close-btn="false"  class="ns-bottom-sheet no-background" noHeader>
        <div class="customer-info-txt txt-center">
          <p class="ment mt30 mb20">
            선택한 설계를 삭제하시겠습니까?
          </p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
            <div class="relative-div">
              <mo-button componentid="" color="primary" shape="primary" size="border" @click="fn_Close" class="ns-btn-round white">아니오</mo-button>
              <mo-button componentid="" color="primary" shape="primary" size="border" @click="fn_ConfirmD" class="ns-btn-round blue">예</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>
      <!-- 설계 삭제 Confirm BottomSheet END -->
    </ur-box-container>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
* 공통   라이브러리 INCLUDE 영역	                                                   *
***********************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import piCommonUtil from '@/ui/pi/common/piCommonUtil'
import MSPPI601P from '@/ui/pi/MSPPI601P'
import Screen from '~systems/mixin/screen'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
export default {
  /***********************************************************************************
    * Vue 파일 속성 정의 영역	                                                       *
    ***********************************************************************************/
  name: 'MSPPI600M',
  screenId: 'MSPPI600M',
  mixins:[Screen],
  components: {
    MSPPI601P
  },
  /***********************************************************************************
    * Life Cycle 함수 정의 영역	                                                     *
    ***********************************************************************************/
  created() {
    let lv_vm = this
    // backKey event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)

    this.actionSlot = {
      actionComponent: {
        template:'<div slot="action" class="icon-wrapper"><mo-button shape="border" class="ns-btn-round btn-title-style-1" @click="fn_AddPlan">My플랜 추가</mo-button><mo-icon type="picto" @click="fn_OpenMSPPI601()">search</mo-icon></div>',
        methods: {
          fn_AddPlan () {
            lv_vm.fn_AddPlan()
          },
          fn_OpenMSPPI601 () {
            lv_vm.fn_OpenPop()
          }
        }
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.backViewId = from.name
      if (vm.$route.params) {
        vm.zaPrdtNmParam = !_.isEmpty(vm.$route.params.zaPrdtNm) ? vm.$route.params.zaPrdtNm : ''// router로 전달받은 상품명
        vm.productCd = !_.isEmpty(vm.$route.params.productCd) ? vm.$route.params.productCd : ''
        vm.params = vm.$route.params
        vm.fn_Init()
      }
    })
  },
  mounted () {
    // 필수 적용 함수
    this.$bizUtil.insSrnLog(this.$options.screenId)
    // window.vue.getStore('progress').dispatch('PART')
    window.vue.getStore('progress').dispatch('SKEL_LIST_CHK')
  },
  /** 설명 : Step-7 인스턴스가 Remove 되기 전 호출 */
  beforeDestroy () {
    // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
  },
  /***********************************************************************************
    * 화면변수 선언 영역	                                                             *
    ***********************************************************************************/
  data() {
    return {
      // 이전화면에서 보낸 param
      params: null,
      // 상품코드
      productCd: null,
      // 상품명
      zaPrdtNmParam: null,
      // 상세조회 분류키
      selectedKey: ' ',
      items: [],
      isSelectAll: false,
      selectItems: [],
      isRstKeyword: false,
      searchInfo: {},
      chkCnt: 0,
      lv_isBtnDlt: false
    }
  },
  /***********************************************************************************
  * Computed 함수 정의 영역                                                           *
  ***********************************************************************************/
  computed: {
    checkCount () {
      return this.items.filter(item => item.checked).length
    },
    getCodeName () {
      let codeName = ''
      if (this.selectedKey === ' ') {
        codeName = '전체'
      } else if (this.selectedKey === '0') {
        codeName = '개인'
      } else if (this.selectedKey === '1') {
        codeName = '지점'
      } else if (this.selectedKey === '2') {
        codeName = '지역단'
      } else if (this.selectedKey === '3') {
        codeName = '사업부'
      } else {
        codeName = ''
      }
      return codeName
    }
  },
  watch: {
    // checkCount () {
    //   // if (this.checkCount === this.items.length && this.items.length !== 0) {
    //   //   this.isSelectAll = true
    //   // } else {
    //   //   this.isSelectAll = false
    //   // }
    // }
  },
  /***********************************************************************************
    * 사용자 함수 정의 영역                                                           *
    ***********************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_HistoryBack
     * 설명       : 뒤로가기 버튼
     ******************************************************************************/
    fn_HistoryBack () {
      if (this.isRstKeyword) {
        // 조회를 한번이라도 한 경우 전체 검색

        // 초기화
        this.selectedKey = ' '
        this.zaPrdtNmParam = ''
        this.productCd = ''
        this.isRstKeyword = false // 검색이력 초기화

        // 바텀시트 초기화
        // if (this.$refs.bottomSheet1 !== undefined) this.$refs.bottomSheet1.close()
        this.lv_isBtnDlt = false
        if (this.$refs.bottomSheet2 !== undefined) this.$refs.bottomSheet2.close()

        // 재조회
        this.fn_Search('S')
      } else {
        // 체크된 항목 확인
        if (this.checkCount > 0) {
          let alertMsg = '현재 화면을 종료하시겠습니까?'
          let alertBottom = this.$bottomModal.open(MSPBottomAlert, {
            properties: {
              type: 'B',
              content: alertMsg,
              title_pos_btn: '나가기'
            },
            listeners: {
              onPopupConfirm: () => {
                this.$bottomModal.close(alertBottom)
                this.$router.push({name: 'MSPBC002M'})
                // this.$router.go(-1)
              },
              onPopupClose: () => {
                this.$bottomModal.close(alertBottom)
              }
            }
          })
        } else {
          // 조회를 하지 않았으면 뒤로가기
          this.$router.push({name: 'MSPBC002M'})
          // this.$router.go(-1)
        }
      }
    },
    /******************************************************************************
    * Function명 : fn_Init
    * 설명       : 
    ******************************************************************************/
    fn_Init () {
      // 상품코드 및 상품 명이 파라미터로 들어올때
      if (!piCommonUtil.isEmpty2(this.zaPrdtNmParam)) {
        this.searchKeyword = this.zaPrdtNmParam
      }
      this.fn_Search('S')
    },
    /******************************************************************************
    * Function명 : fn_Search
    * 설명       : 마이플랜 목록 조회 I/F 호출
    ******************************************************************************/
    fn_Search (pagingGubun) {
      // this.isLoadingStatus = true
      if (pagingGubun === 'S') { // 최초 조회
        // 기존 데이터 초기화
        this.isSelectAll = false
        this.items = []
        this.selectItems = []
        // this.items.splice(0)
        // this.selectItems.splice(0)
        window.vue.getStore('progress').dispatch('SKEL_LIST_CHK')
      } else {
        if (this.items.length === 0 || (this.items.length % 10 !== 0 && this.items.length % 10 < 10)) return
        // if (this.items.length % 10 === 0 || (this.items.length % 10 !== 0 && this.items.length % 10 < 10)) return
        // if (this.items.length < 10) return
        else {
          window.vue.getStore('progress').dispatch('PART')
        }
      }
      // 조건별로 조회
      let functionName = 'URLPQL00366' // /quo/cbo/ZJPQL0900/selListMyPlan.svc
      let zMyPlanBasInfoDto = {}
      zMyPlanBasInfoDto.zaMyPnLvlCd = this.selectedKey // 분류코드
      zMyPlanBasInfoDto.productCd = this.productCd // 상품코드
      zMyPlanBasInfoDto.zaScCd = 'M' // 현재 판매중인 상품명
      zMyPlanBasInfoDto.zaClctCnsltNo = this.getStore('userInfo').getters.getUserInfo.userId
      // GA 매니저 대행 컨설턴트 변경시
      if(this.$cmUtil.fn_GachangeTF()){
        zMyPlanBasInfoDto.zaClctCnsltNo = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
      }
      zMyPlanBasInfoDto.zaTotRowCnt = this.items.length // 현재 테이블 갯수
      zMyPlanBasInfoDto.zaAddRowCnt = 10 // 10개씩..

      let PIF1PQL0343VO = {}
      PIF1PQL0343VO.fnScCd = 'S'
      PIF1PQL0343VO.zMyPlanBasInfoVO = [zMyPlanBasInfoDto]
      let inputJson = {}
      inputJson.PIF1PQL0343VO = [PIF1PQL0343VO]
      window.vue.getStore('progress').dispatch('SHOW')
      // window.vue.getStore('progress').dispatch('UPDATE', true)
      piCommonUtil.invoke(functionName, inputJson, this.fn_SearchSuccess, null, this.executeUrlCallbackE, this, this)
    },
    /******************************************************************************
    * Function명 : fn_SearchSuccess
    * 설명       : 마이플랜 목록 조회 I/F 호출 성공 콜백
    ******************************************************************************/
    fn_SearchSuccess (result) {
      window.vue.getStore('progress').dispatch('HIDE')
      // window.vue.getStore('progress').dispatch('UPDATE', false)
      this.isLoadingStatus = false
      let resultData = result.pif1PQL0343VO[0]['zmyPlanBasInfoVO']
      let userId = this.getStore('userInfo').getters.getUserInfo.userId
      // GA 매니저 대행 컨설턴트 변경시
      if(this.$cmUtil.fn_GachangeTF()){
        userId = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
      }
      // 검색한 결과
      if (piCommonUtil.isObj(resultData) && resultData.length > 0) {
        // 설정인 이름하고 사번 설정
        for (let i = 0; i < resultData.length; i++) {
          // 설정인과 로그인 사번이 동일할때만 체크 가능.
          resultData[i].checked = false
          if (resultData[i].zaClctCnsltNo === userId) {
            resultData[i].disabled = false
            resultData[i].class = 'crTy-blue3'
            // resultData[i].styleObject = {color: 'blue'}
          } else {
            // crTy-blue3 crTy-bk1-
            resultData[i].disabled = true
            resultData[i].class = 'crTy-bk1'
            // resultData[i].styleObject = {}
          }
        }
        this.items = this.items.concat(resultData)
        // console.log('마이플랜 조회 결과 ===> ', this.items)
      } else {
        this.status = '2'
      }
    },
    /******************************************************************************
    * Function명 : executeUrlCallbackE
    * 설명       : 마이플랜 목록 조회 I/F 호출 에러 콜백
    ******************************************************************************/
    executeUrlCallbackE (response) {
      // window.vue.getStore('progress').dispatch('UPDATE', false)
      window.vue.getStore('progress').dispatch('HIDE')
      this.getStore('confirm').dispatch('ADD', JSON.stringify(response.msgComm))
    },
    /******************************************************************************
    * Function명 : fn_checkedList
    * 설명       : 리스트 체크박스 선택 시
    ******************************************************************************/
    fn_checkedList (disabled, index) {
      
      if (disabled) {
        this.items[index].checked = false
        this.getStore('toast').dispatch('ADD', 'My플랜 삭제는 설정인에 한하여 가능합니다.')
      }

      this.selectItems = []
      this.selectItems = this.items.filter(item => item.checked)
      let chkCnt = this.items.filter(chkItem => !chkItem.disabled)
      if (this.selectItems.length > 0 && this.selectItems.length === chkCnt.length) {
        this.isSelectAll = true
      } else {
        this.isSelectAll = false
      }
      // console.log('checked Length ===> ', this.selectItems.length)
      // console.log('bottomSheet1 status ===> ', this.$refs.bottomSheet1)
      this.$nextTick(() => {
        // this.items[index].checked = false

        if (this.selectItems.length > 0) {
          this.lv_isBtnDlt = true
          // this.$refs.bottomSheet1.open()
        } else {
          this.isSelectAll = false
          this.lv_isBtnDlt = false
          // this.$refs.bottomSheet1.close()
        }
      })
    },
    /******************************************************************************
    * Function명 : selectAllItemsFunc
    * 설명       : 전체 선택 시 함수
    ******************************************************************************/
    selectAllItemsFunc (v) {
      if (v) {
        // checked
        this.selectItems.splice(0)
        this.items.forEach((el, i) => {
          if (el.disabled) { return }
          el.checked = true
          this.selectItems.push(el)
        })
        if (this.selectItems.length > 0) {
          this.lv_isBtnDlt = true
          // this.$refs.bottomSheet1.open()
        } else {
          this.isSelectAll = false
          this.lv_isBtnDlt = false
          // this.$refs.bottomSheet1.close()
        }
      } else {
        // unchecked
        this.items.forEach(item => item.checked = false)
        this.selectItems.splice(0, this.selectItems.length)
        this.lv_isBtnDlt = false
        // this.$refs.bottomSheet1.close()
      }
    },
    /******************************************************************************
    * Function명 : fn_OpenPop
    * 설명       : 조회아이콘 선택 시 함수
    ******************************************************************************/
    fn_OpenPop () {
      let lv_vm = this
      let openMSPPI601P = this.$bottomModal.open(MSPPI601P, {
        properties: {
          pPage: 'MSPPI600M',
          searchInfo: {zaMyPnLvlCd: lv_vm.selectedKey, zaPrdtNm: lv_vm.zaPrdtNmParam, productCd: lv_vm.productCd}
        },
        listeners: {
          onPopupSearch: (item) => {
            // console.log('MSPPI601P close == onPopupSearch', item)
            lv_vm.lv_isBtnDlt = false
            lv_vm.$bottomModal.close(openMSPPI601P)
            if (!_.isEmpty(item)) {
              lv_vm.selectedKey = item.zaMyPnLvlCd
              lv_vm.zaPrdtNmParam = item.zaPrdtNm
              lv_vm.productCd = item.productCd
              // this.items = []
              // this.selectItems = []
              lv_vm.fn_Search('S')
              // 검색결과 영역 노출 여부
              this.$nextTick(() => {
                if (!this.$bizUtil.isEmpty(lv_vm.selectedKey.trim()) || (lv_vm.zaPrdtNmParam != '전체' && !this.$bizUtil.isEmpty(lv_vm.productCd))) {
                  lv_vm.isRstKeyword = true
                } else {
                  lv_vm.isRstKeyword = false
                }
              })
            }
          }
        }
      })
    },
    /******************************************************************************
    * Function명 : fn_Delete
    * 설명       : 설계삭제 버튼 선택 시 함수
    ******************************************************************************/
    fn_Delete () {
      this.lv_isBtnDlt = false
      // this.$refs.bottomSheet1.close()
      this.$refs.bottomSheet2.open()
    },
    /******************************************************************************
    * Function명 : fn_Close
    * 설명       : 설계삭제 여부 컨펌창에서 아니오 선택 시 함수
    ******************************************************************************/
    fn_Close() {
      this.$refs.bottomSheet2.close()
    },
    /******************************************************************************
    * Function명 : fn_ConfirmD
    * 설명       : 마이플랜 삭제 I/F 호출
    ******************************************************************************/
    fn_ConfirmD () {
      this.$refs.bottomSheet2.close()
      let urlId = 'URLPQL00366'
      let zMyPlanBasInfoDto = {}
      let zMyPlanClbyInfoListDto = []
      let zMyPlanClbyInfoDto = {}

      zMyPlanBasInfoDto.zaClctCnsltNo = this.getStore('userInfo').getters.getUserInfo.userId
      // GA 매니저 대행 컨설턴트 변경시
      if(this.$cmUtil.fn_GachangeTF()){
        zMyPlanBasInfoDto.zaClctCnsltNo = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
      }

      this.selectItems.forEach((el, i) => {
        zMyPlanClbyInfoDto = {}
        zMyPlanClbyInfoDto.zaMyPnId = el.zaMyPnId
        zMyPlanClbyInfoDto.zaClctCnsltNo = el.zaClctCnsltNo
        zMyPlanClbyInfoListDto.push(zMyPlanClbyInfoDto)
      })

      zMyPlanBasInfoDto.zMyPlanClbyInfoVO = zMyPlanClbyInfoListDto // 마이플랜별 설계 정보

      let PIF1PQL0343VO = {}
      PIF1PQL0343VO.fnScCd = 'D'
      PIF1PQL0343VO.zMyPlanBasInfoVO = [zMyPlanBasInfoDto]
      let inputJson = {}
      inputJson.PIF1PQL0343VO = [PIF1PQL0343VO]
      // URLPQL00366 : /quo/cbo/ZJPQL0900/insMyPlan.svc
      window.vue.getStore('progress').dispatch('SHOW')
      // window.vue.getStore('progress').dispatch('UPDATE', true)
      piCommonUtil.invoke(urlId, inputJson, this.fn_DeleteSuccess, null, this.executeUrlCallbackE, this, this)
    },
    /******************************************************************************
    * Function명 : fn_DeleteSuccess
    * 설명       : 마이플랜 삭제 처리 성공 콜백
    ******************************************************************************/
    fn_DeleteSuccess: function (result) {
      window.vue.getStore('progress').dispatch('HIDE')
      // window.vue.getStore('progress').dispatch('UPDATE', false)

      let resultData = result.pif1PQL0343VO[0]['zmyPlanBasInfoVO'][0]

      if (resultData.zaTrtStatCd === '00') { // 성공
        this.selectItems.forEach((el, i) => {
          let pos = this.items.indexOf(el)
          this.items.splice(pos, 1)
        })

        this.getStore('toast').dispatch('ADD', '설계가 삭제되었습니다.')
        // this.getStore('toast').dispatch('ADD', resultData.zaMsgCntnt)
      } else if (resultData.zaTrtStatCd === '01') { // 실패
        piCommonUtil.getFDPConfirm('s', resultData.zaMsgCntnt)
      } else { // 실패
        piCommonUtil.getFDPConfirm('s', 'My플랜정보 삭제 오류가 발생하였습니다.')
      }

      // 삭제 성공 또는 실패 했을 경우 초기화
      this.selectItems.splice(0)
    },
    /******************************************************************************
    * Function명 : fn_AddPlan
    * 설명       : My플랜 추가 버튼 선택 시 가입설계 메인화면으로 이동
    ******************************************************************************/
    fn_AddPlan () {
      this.$router.push({name: 'MSPPI001M'})
    },
    // fn_ClickBtnPrevious () {this.$router.go(-1)},
    // // fn_ClickInitial () {return false},
    
    // fn_open () {
    //   // if(this.value2)this.value2 = false
    //   // else this.value2 = true
    //   this.$bizUtil.showCustomBottomSheet(this, this.$refs.bottomSheet2, true)
    //   // this.$refs.bottomSheet2.open()
    // },
  }
}
</script>
<style scoped>
</style>