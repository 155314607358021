/*
 * 업무구분: 가입설계
 * 화 면 명: MSPPI601P
 * 화면설명: My플랜 상세조회
 * 접근권한: 
 * 작 성 일: 2023.01.25
 * 작 성 자: ---
 */
 <template>
  <ur-page-container class="msp" title="My플랜 상세조회" :show-title="true" :topButton="true" type="subpage">
  <!-- <ur-page-container class="msp" title="My플랜 상세조회" :show-title="true" :topButton="true" type="popup"> -->
    <!-- Content영역 -->
    <ur-box-container alignV="start" componentid="" direction="column" class="ns-info-area">        
      <ur-box-container alignV="start" componentid="" direction="column" class="info-box">
        <div class="info-title-wrap mt30">
          <span class="label-title">분류</span>
        </div>
        <msp-bottom-select class="ns-dropdown-sheet" :items="selectBoxItems" :itemValue="'key'" :itemText="'label'" v-model="selectBoxValue" underline bottom-title="분류선택" @selectedItem="fn_selectedItem" closeBtn scrolling/>
        <div class="info-title-wrap mt20">
          <span class="label-title">상품</span>
        </div>
        <mo-text-field searchable clearable underline v-model="searchKeyword" readonly @click-icon="fn_OpenPopup"/>
      </ur-box-container>
    </ur-box-container>
    <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
      <div class="relative-div">
        <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_Reset">초기화</mo-button>
        <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Search">조회</mo-button>
      </div>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
* 공통 라이브러리 INCLUDE 영역	                                                    *
***********************************************************************************/
import MSPPI602P from '@/ui/pi/MSPPI602P'
export default {
  /***********************************************************************************
  * Vue 파일 속성 정의 영역	                                                         *
  ***********************************************************************************/
  name: 'MSPPI601P',
  screenId: 'MSPPI601P',
  props: {
    searchInfo: Object
  },
  modalProps: {
    full: true,
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  components: {},
  /***********************************************************************************
  * Life Cycle 함수 정의 영역	                                                       *
  ***********************************************************************************/
  created() {},
  mounted() {
    let lv_vm = this
    // 필수 적용 함수
    this.$bizUtil.insSrnLog(this.$options.screenId)
    // console.log('[MSPPI601P] mounted ===> ', lv_vm.searchInfo)
    if (!_.isEmpty(lv_vm.searchInfo)) {
      lv_vm.fn_init()
      
    }
  },
  /***********************************************************************************
  * 화면변수 선언 영역	                                                              *
  ***********************************************************************************/
  data() {
    return {
      selectBoxValue: {
        key: ' ',
        label: '전체'
      },
      selectBoxItems: [{
        key: ' ',
        label: '전체'
      },
      {
        key: '0',
        label: '개인'
      },
      {
        key: '1',
        label: '지점'
      },
      {
        key: '2',
        label: '지역단'
      },
      {
        key: '3',
        label: '사업부'
      }],
      searchKeyword: '전체',
      productCd: ''
    }
  },
  /***********************************************************************************
  * Computed 함수 정의 영역                                                           *
  ***********************************************************************************/
  computed: {
  },
  watch: {
    searchKeyword (keyword) {
      // console.log('keyword watch ===> ', keyword)
      if (keyword.length === 0) {
        this.$nextTick(() => {
          this.searchKeyword = '전체'
          this.productCd = ''
        })
      }
    }
  },
  /***********************************************************************************
  * 사용자 함수 정의 영역                                                             *
  ***********************************************************************************/
  methods: {
    fn_init () {
      this.selectBoxValue = this.searchInfo.zaMyPnLvlCd
      // lv_vm.selectBoxValue = lv_vm.selectBoxItems.find(item => item.key === lv_vm.searchInfo.zaMyPnLvlCd)
      // console.log('this.selectBoxValue ====> ', this.selectBoxValue)
      if (!_.isEmpty(this.searchInfo.zaPrdtNm) && !_.isEmpty(this.searchInfo.productCd)) {
        this.$nextTick(() => {
          this.searchKeyword = this.searchInfo.zaPrdtNm
          this.productCd = this.searchInfo.productCd
        })
      } else {
        this.$nextTick(() => {
          this.searchKeyword = '전체'
          this.productCd = ''
        })
      }
    },
    fn_selectedItem (item) {
      // console.log('fn_selectedItem ===> ', item)
    },
    /******************************************************************************
    * Function명 : fn_Reset
    * 설명       : 검색 조건 초기화
    ******************************************************************************/
    fn_Reset () {
      this.selectBoxValue = ' '
      this.searchKeyword = '전체'
      this.productCd = ''
    },
    /******************************************************************************
    * Function명 : fn_Search
    * 설명       : 조회 버튼 선택 시 검색조건 세팅
    ******************************************************************************/
    fn_Search () {
      this.$emit('onPopupSearch', {zaMyPnLvlCd: this.selectBoxValue, zaPrdtNm: this.searchKeyword, productCd: this.productCd})
      // this.$emit('onPopupSearch', {zaMyPnLvlCd: this.selectBoxValue.key, zaPrdtNm: this.searchKeyword, productCd: this.productCd})
    },
    /******************************************************************************
    * Function명 : fn_OpenPopup
    * 설명       : 상품선택 팝업 오픈
    ******************************************************************************/
    fn_OpenPopup () {
      let lv_vm = this
      // searchKeyword: '전체',
      // productCd: ''
      let openMSPPI602P = this.$bottomModal.open(MSPPI602P, {
        properties: {
          pPage: 'MSPPI601P',
          showMSPPI602P: true,
          searchInfo: {zaPrdtNm: this.searchKeyword, productCd: this.productCd}
        },
        listeners: {
          applyInfo: (resultData) => {
            // console.log('MSPPI602P close == applyInfo')
            lv_vm.$bottomModal.close(openMSPPI602P)
            if (resultData) {
              lv_vm.searchKeyword = resultData.prtPrdtNm // 상품명
              lv_vm.productCd = resultData.prdtNm.split('_')[0] + '_' + resultData.prdtNm.split('_')[1] // 상품코드
            }
          },
          onPopupCancel: () => {
            // console.log('MSPPI602P close == onPopupCancel')
            lv_vm.$bottomModal.close(openMSPPI602P)
          },
        }
      },
      // {
      //   properties:{
      //     noHeader:true
      //   }
      // }
      )
    },
  }
}
</script>
<style scoped>
</style>