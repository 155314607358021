/*
 * 업무구분: 가입설계
 * 화 면 명: MSPPI602P
 * 화면설명: My플랜 상품선택
 * 접근권한: 
 * 작 성 일: 2023.01.25
 * 작 성 자: ---
 */
 <template>
  <ur-page-container class="msp" :show-title="true" title="상품선택" :topButton="true" type="subpage" ref="page">
  <!-- <ur-page-container class="msp" :show-title="true" title="상품선택" :topButton="true" type="popup" ref="page"> -->
    <ur-box-container direction="column" alignV="start" class="msp-pi-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview">

          <!-- stickey 영역 -->
          <template #fixed="{scrollRate}" >
            <!-- <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow >
              <div slot="nav" class="icon-wrapper" @click="fn_cancle()">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '상품선택' : '상품선택'}}</div>
            </mo-top-bar> -->

            <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-contents mt20 mb20">
              <mo-text-field  class="form-input-name full" searchable clearable underline placeholder="상품명으로 검색해 주세요" v-model="searchKeyword" max-length="20" @click-icon="fn_filterList" @enter="fn_filterList"/>
            </ur-box-container>
            <div class="acodian-btn" :class="{ open: event01 }" > <!-- class명 open시 "acodian-pop" 영역이 펼쳐집니다. -->
              <mo-tab-box class="ns-move-tab-box h46" default-idx="1" ref="tabBox" style="height:46px">
                <mo-tab-label v-for="(item, idx) in prdtCtgryList" :key="idx" :idx="`${idx + 1}`" @click="fn_tabIdx(`${idx+1}`)">{{ item.label }}</mo-tab-label>
              </mo-tab-box>
              <div class="btn fexTy1" @click="setopen()">
                <mo-button class="link-arrow down"></mo-button>
              </div>

              <div class="acodian-pop">
                <div class="acodian-bg" @click="setopen()" style="top:250px" /><!-- 어둑어둑 배경입니다. class명 "acodian-btn" 의 top 값이 입력될수 있도록 해주세요. -->
                <div class="acodian-con">
                  <div class="title-row fexTy3">
                    <span class="fs19rem fwb">상품군 바로가기</span>
                    <mo-button class="link-arrow up" @click="setopen()"></mo-button>
                  </div>
                  <div class="con-area">
                    <mo-button v-for="(ctgry, index) in prdtCtgryList" :key="index" @click="fn_tabIdx(`${index+1}`)" class="ns-btn-round msp-btn mwauto h40" :class="{ on: tab_index === `${index+1}` }">{{ctgry.label}}</mo-button>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <!-- scroll 영역 -->
          <template #scroll>
            <ur-box-container v-if="filterList.length > 0" alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area">              
              <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list status-list-ty2">
                <mo-list :list-data="filterList">
                  <template #list-item="{item}">
                    <mo-list-item>
                      <div class="list-item__contents">
                        <div class="list-item__contents__title fexTy3 mb0">
                          <span class="name fwn fs19rem" @click="fn_prdtListItemClick(item)">{{item.prtPrdtNm}}</span>
                          <span class="status-red" v-if="value2 === true"></span>
                        </div>
                      </div>
                    </mo-list-item>
                  </template>
                </mo-list>
              </ur-box-container>
              <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 -->
              <div class="ns-height100"></div>
            </ur-box-container>
            <!-- NoData 영역: start  -->
            <ur-box-container v-else alignV="start" componentid="ur_box_container_002" direction="column" class="no-data">
              <mo-list-item>
                <div class="list-item__contents">
                  <div class="list-item__contents__info">
                    <span>데이터가 없습니다.</span>
                  </div>
                </div>
              </mo-list-item>
            </ur-box-container>
            <!-- NoData 영역: end  -->
          </template>
        </mo-collapsing-header-view>
      </div>
    </ur-box-container> 
    <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
      <div class="relative-div">
        <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_cancle">취소</mo-button>
      </div>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
* 공통 라이브러리 INCLUDE 영역	                                                   *
***********************************************************************************/
import piCommonUtil from '@/ui/pi/common/piCommonUtil'
import Screen from '~systems/mixin/screen'
export default {
  /***********************************************************************************
  * Vue 파일 속성 정의 영역	                                                       *
  ***********************************************************************************/
  name: 'MSPPI602P',
  screenId: 'MSPPI602P',
  mixins:[Screen],
  components: { },
  modalProps: {
    full: true,
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  props: {
    // showMSPPI602P: Boolean,
    searchInfo: Object
  },
  /***********************************************************************************
  * Life Cycle 함수 정의 영역	                                                        *
  ***********************************************************************************/
  created() {
  },
  mounted () {
    // 필수 적용 함수
    this.$bizUtil.insSrnLog(this.$options.screenId)
    this.prdtCtgryList = []
    this.prdtPosSellList = []
    this.prdtRecentSellList = []
    window.vue.getStore('progress').dispatch('SKEL_LIST_CHK')
    this.fn_getServiceData('URLPQL08172')
  // 스크롤 애니메이션 instance 선언
    // this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
    // document.getElementById(this.lv_HeaderviewScrollCompID).addEventListener('scroll', this.$refs.page.onScrollEvent)
  },
  /***********************************************************************************
  * 화면변수 선언 영역	                                                               *
  ***********************************************************************************/
  data() {
    return {
      searchKeyword: '',
      prdtCtgryList: [],
      prdtPosSellList: [],
      selectedCategory: '',
      selectedPrdt: {},
      prdtRecentSellList: [],
      filterList: [],
      tab_index: '1', // tab contant 선택;
      value2: false,
      event01 : false,
      unique_productId: [],
      lv_HeaderviewScrollCompID: ''
    }
  },
  watch : {
    selectedCategory (nNm, oNm) {
      // console.log(`selectedCategory watch ====> newNm : ${nNm} / oldNm : ${oNm}`)
      if ( nNm !== '' && nNm !== 'ALL' && nNm !== 'RECENT') {
        this.filterList = this.prdtPosSellList.filter((prdt) => {
          return prdt.entplPrdtClsfCd === nNm
        })
      } else {
        if (nNm === 'ALL' || nNm === '') {
          this.filterList = this.unique_productId
          // this.filterList = this.prdtPosSellList
        } else if ( nNm === 'RECENT') {
          this.filterList = this.prdtRecentSellList
        }
      }
    },
    searchKeyword (keyword) {
      if (keyword.length > 0) {
        // 상품 검색 입력 시 카테고리 전체로 변경
        let fIndex = this.prdtCtgryList.findIndex(item => item.data === 'ALL')
        // console.log('fIndex ====> ', fIndex)
        if (`${fIndex + 1}` !== this.tab_index) {
          this.fn_tabIdx(`${fIndex + 1}`)
        }
        this.fn_filterList()
      } else {
        // 전체 리스트 세팅
        if (this.selectedCategory === 'ALL') {
          this.filterList = this.unique_productId
        }
      }
    }
  },
  /***********************************************************************************
  * Computed 함수 정의 영역                                                           *
  ***********************************************************************************/
  computed: {
  },
  /***********************************************************************************
  * 사용자 함수 정의 영역                                                             *
  ***********************************************************************************/
  methods: {
    /******************************************************************************
    * Function명 : fn_getServiceData
    * 설명       : 카테고리검색(URLPQL08172) 및 상품검색(URLPQL08166)
    ******************************************************************************/
    fn_getServiceData (serviceName) {
      window.vue.getStore('progress').dispatch('SHOW')
      if (serviceName === 'URLPQL08172') { // 판매상품 카테고리 조회
        let inputJson = {
          category: '',
          basedate: '',
          istablet: '',
          orgcode1: '',
          orgcode2: '',
          orgcode3: ''
        }
        piCommonUtil.invoke(serviceName, inputJson, this.URLPQL08172_LocalCallBack, null, this.URLPQL08172_LocalCallBack, this, this)
      } else if (serviceName === 'URLPQL08166') { // 판매가능상품목록 조회
        let inputJson = {
          reprPrdtPgScCd: '01',
          stndYmd: '',
          tbtPlanYn: 'Y',
          orgNo01: this.getStore('userInfo').getters.getUserInfo.onpstHofOrgNo,
          orgNo02: this.getStore('userInfo').getters.getUserInfo.onpstDofNo,
          orgNo03: this.getStore('userInfo').getters.getUserInfo.onpstFofOrgNo
        }
        piCommonUtil.invoke(serviceName, inputJson, this.URLPQL08166_LocalCallBack, null, this.URLPQL08166_LocalCallBack, this, this)
      } else if (serviceName === 'URLPQL08167') { // 최근 판매상품 목록 조회
        let inputJson = {
          zRntPrdtInfoInptDto: {
            category: '01',
            baseDate: piCommonUtil.getCurrentDate(),
            istablet: 'Y',
            orgcode1: this.getStore('userInfo').getters.getUserInfo.onpstHofOrgNo,
            orgcode2: this.getStore('userInfo').getters.getUserInfo.onpstDofNo,
            orgcode3: this.getStore('userInfo').getters.getUserInfo.onpstFofOrgNo,
            zaClctCnsltNo: this.getStore('userInfo').getters.getUserInfo.userId
          }
        }
        // GA 매니저 대행 컨설턴트 변경시
        if(this.$cmUtil.fn_GachangeTF()){
          inputJson.zRntPrdtInfoInptDto.zaClctCnsltNo = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
        }
        piCommonUtil.invoke(serviceName, inputJson, this.URLPQL08167_LocalCallBack, null, this.URLPQL08167_LocalCallBack, this, this)
      }
    },
    /******************************************************************************
    * Function명 : URLPQL08172_LocalCallBack
    * 설명       : 판매상품 카테고리 조회 URLPQL08172_LocalCallBack
    ******************************************************************************/
    URLPQL08172_LocalCallBack (result) {
      if (result === null || result === undefined || !piCommonUtil.isObj(result.pisalePrdtCategoryListVO)) {
        // this.getStore('confirm').dispatch('ADD', '상품카테고리 조회결과가 없습니다.')
        piCommonUtil.getFDPConfirm('s', '상품카테고리 조회결과가 없습니다.')
        // window.vue.getStore('progress').dispatch('UPDATE', false)
        return false
      } else {
        console.log('상품카테고리 조회성공 !!!!!!!!')
        let allCat = {label: '전체', data: 'ALL', enable: false}
        let recentCat = {label: '최근 선택', data: 'RECENT', enable: false}

        this.prdtCtgryList.push(recentCat)
        this.prdtCtgryList.push(allCat)

        result.pisalePrdtCategoryListVO.forEach((category) => {
          this.prdtCtgryList.push({label: category.prdtClsfNm, data: category.prdtClsfCd, enable: false})
        })

        this.fn_getServiceData('URLPQL08166')
        console.log('서비스성공  조회건수 : ' + this.prdtCtgryList.length)
      }
    },
    /******************************************************************************
    * Function명 : URLPQL08167_LocalCallBack
    * 설명       : 최근 판매상품 목록 조회 URLPQL08167_LocalCallBack
    ******************************************************************************/
    URLPQL08167_LocalCallBack (result) {
      window.vue.getStore('progress').dispatch('HIDE')
      if (result === null || result === undefined || !piCommonUtil.isObj(result.zrntPrdtInfoInptDto.zsalePrdtListPrtDtos)) {
        // this.getStore('confirm').dispatch('ADD', '판매상품목록 조회결과가 없습니다.')
        piCommonUtil.getFDPConfirm('s', '최근판매상품목록 조회결과가 없습니다.')
        // window.vue.getStore('progress').dispatch('UPDATE', false)
        return false
      } else {
        console.log('최근판매상품목록 조회성공 !!!!!!!! : ')
        this.prdtRecentSellList = result.zrntPrdtInfoInptDto.zsalePrdtListPrtDtos

        this.prdtRecentSellList.forEach((prdt, index) => {
          prdt.prtPrdtNm = prdt.name
          prdt.prdtNm = prdt.product
          prdt.prcd = prdt.contract
          this.prdtCtgryList.forEach((category, index) => {
            // console.log(category.data + ' : ' + prdt.entplPrdtClsfCd)
            if ( category.data === prdt.salesgroup) {
              prdt.categoryName = category.label
            }
          })
        })
        console.log('서비스성공  조회건수 : ' + this.prdtCtgryList.length)
        this.selectedCategory = 'RECENT'
        // this.fn_set_categoryFilter('RECENT', 0)
      }
    },
    /******************************************************************************
    * Function명 : URLPQL08166_LocalCallBack
    * 설명       : 판매가능상품목록 조회 URLPQL08166_LocalCallBack
    ******************************************************************************/
    URLPQL08166_LocalCallBack (result) {
      if (result === null || result === undefined || !piCommonUtil.isObj(result.pisalePrdtInfoVO)) {
        // this.getStore('confirm').dispatch('ADD', '판매상품목록 조회결과가 없습니다.')
        piCommonUtil.getFDPConfirm('s', '판매가능상품목록 조회결과가 없습니다.')
        // window.vue.getStore('progress').dispatch('UPDATE', false)
        return false
      } else {
        console.log('판매가능상품목록 조회성공 !!!!!!!! : ')
        this.prdtPosSellList = result.pisalePrdtInfoVO
        console.log('prdtPosSellList ===> ', JSON.stringify(this.prdtPosSellList))
        this.unique_productId = []
        this.prdtPosSellList.forEach((el) => {
          let inArr = this.unique_productId.some(function (el_u) {
            return el_u.prdtNm === el.prdtNm
          })

          if (!inArr) {
            this.unique_productId.push(el)
          }
        })

        // this.prdtPosSellList.forEach((prdt, index) => {
        //   this.prdtCtgryList.forEach((category, index) => {
        //     // console.log(category.data + ' : ' + prdt.entplPrdtClsfCd)
        //     if ( category.data === prdt.entplPrdtClsfCd) {
        //       prdt.categoryName = category.label
        //     }
        //   })
        // })
        console.log('서비스성공  조회건수 : ' + this.prdtCtgryList.length)
        // Gaf.setProductList(this.prdtPosSellList)
        this.fn_getServiceData('URLPQL08167')
      }
    },
    /******************************************************************************
    * Function명 : fn_filterList
    * 설명       : 검색어로 전체 상품 목록에서 필터
    ******************************************************************************/
    fn_filterList () {
      // this.prdtPosSellList
      let lv_vm = this
      let fList = []
      if (lv_vm.searchKeyword.length > 0) {
        fList = lv_vm.unique_productId.filter(item => {
          return item.prtPrdtNm.includes(lv_vm.searchKeyword)
        })
        // fList = lv_vm.prdtPosSellList.filter(item => {
        //   return item.hasOwnProperty('categoryName') && item.prtPrdtNm.includes(lv_vm.searchKeyword) && item.entplPrdtClsfCd !== '1'
        // })
        if (fList.length > 0) {
          lv_vm.filterList = fList
        } else {
          lv_vm.filterList = []
        }
      }
    },
    /******************************************************************************
    * Function명 : fn_tabIdx
    * 설명       : 
    ******************************************************************************/
    fn_tabIdx (str) {
      // console.log('fn_tabIdx ====> ', str)
      this.tab_index = str
      this.selectedCategory = this.prdtCtgryList[str-1].data
      // console.log('selectedCategory ===> ', this.selectedCategory)
      this.$refs.tabBox.setIdx(str)
    },
    /******************************************************************************
    * Function명 : fn_prdtListItemClick
    * 설명       : 상품 선택시 호출 메서드
   ******************************************************************************/
    fn_prdtListItemClick (selectProduct) {
      this.selectedPrdt = selectProduct
      this.value2 = true
      this.fn_applyInfo()
    },
    /******************************************************************************
    * Function명 : fn_cancle
    * 설명       : 취소 버튼 선택 시 팝업 종료 함수
    ******************************************************************************/
    fn_cancle () {
      this.$emit('onPopupCancel')
    },
    /******************************************************************************
    * Function명 : fn_applyInfo
    * 설명       : 상품 선택적용
    ******************************************************************************/
   fn_applyInfo () {
      this.$emit('applyInfo', this.selectedPrdt)
    },
    setopen () {
      this.event01=!this.event01
    },
  },
}
</script>
<style scoped>
</style>